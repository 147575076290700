import {
  Accessor as SolidAccessor,
  children as solidChildren,
  createComputed,
  createMemo,
  createRoot,
  getOwner,
  Owner as SolidOwner,
  runWithOwner,
  untrack,
  useContext as solidUseContext,
} from "solid-js";

import UArray from "@repo/utils/UArray";
import UWeakMap from "@repo/utils/UWeakMap";
import Platform from "@repo/utils-client/Platform";

namespace USolid {
  // For use when destructuring children, children helper name will collide
  export const children = solidChildren;
  // useContext is a useful name in namespaces, use this when name collides
  export const useContext = solidUseContext;

  export const runMemo = <T,>(fn: () => T) => {
    const owner = getOwner();
    return owner ? createMemo(fn)() : fn();
  };

  export const isElementSsr = (
    maybeElement: any,
  ): maybeElement is HTMLElement | { t: string } =>
    Platform.isClient
      ? maybeElement instanceof HTMLElement
      : typeof maybeElement === "object" &&
        UArray.equal(Object.keys(maybeElement), ["t"]);

  export namespace Accessor {
    export const matchValue = <T, R extends T>(
      signal: SolidAccessor<T>,
      predicate: (value: T) => value is R,
    ) =>
      new Promise<R>((resolve) => {
        const value = signal();

        if (predicate(value)) resolve(value);

        createRoot((dispose) =>
          createComputed(() => {
            const value = signal();

            if (predicate(value)) {
              resolve(value);
              dispose();
            }
          }),
        );
      });
    export function split<T>(accessor: SolidAccessor<T>) {
      return new Proxy(accessor, {
        get(target: any, prop) {
          target = createMemo(target);

          if (prop === Symbol.iterator)
            return function* () {
              const value = untrack(target);

              if (
                !(
                  value &&
                  typeof value === "object" &&
                  Symbol.iterator in value
                )
              )
                throw new Error(`${value} is not iterable.`);

              let i = 0;
              // Array specific iterator
              if (Array.isArray(value))
                for (; i < value.length; ++i)
                  yield createMemo(() => target()[i]);
              // Generic iterator
              else
                for (const // eslint-disable-next-line no-unused-vars
                  _ of value as any) {
                  yield createMemo(() => {
                    let j = 0;
                    for (const item of target()) {
                      if (i === j) return item;
                      ++j;
                    }
                  });
                  ++i;
                }
            };

          const value: Record<any, any> = untrack(target);
          return prop in value ? createMemo(() => target()[prop]) : undefined;
        },
      }) as any as { [Key in keyof T]: () => T[Key] };
    }
  }

  export namespace Owner {
    const undefinedSymbol = { symbol: "undefined" };
    const onceCache = new WeakMap<
      SolidOwner | typeof undefinedSymbol,
      Set<any>
    >();
    export function runOnce(
      owner: SolidOwner | null,
      key: any,
      fn: () => void,
    ) {
      const keyMap = UWeakMap.getOr.lazy(
        onceCache,
        owner || undefinedSymbol,
        () => {
          if (owner)
            (owner.cleanups ||= []).push(() => {
              onceCache.delete(owner);
            });
          return new Set();
        },
      );

      if (keyMap.has(key)) return;
      keyMap.add(key);

      runWithOwner(owner, fn);
    }
    export function make() {
      let owner: SolidOwner = null!;
      let dispose: () => void = null!;
      createRoot((d) => {
        owner = getOwner()!;
        dispose = d;
      });
      return Object.assign(owner, { dispose });
    }
  }
}

export default USolid;
