namespace Platform {
  export const isClient = typeof window !== "undefined";

  export const keys = ["web", "ios", "android"] as const;
  export type Key = (typeof keys)[number];
  export namespace Key {
    export const format = (() => {
      const formattedByKey = {
        web: "Web",
        android: "Android",
        ios: "iOS",
      } satisfies Record<Key, string>;

      return (key: Key) => formattedByKey[key];
    })();
  }
}

export default Platform;
